.App {
  margin: 0;
  padding: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  
  background-size: cover;
  background-color: #F6F5F1;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.polaroid-container {
  position: relative;
}

.polaroid-main-image {
  max-width: 300px;
}

.polaroid-tape-image {
  position: absolute;
  top: -20px;
  right: 50%;
  left: 20%;
}

.settings-wrapper {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.settings-icon-button {
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
}

.settings-icon-image {
  width: 32px;
  height: auto;
}

.settings-icon-image:hover {
  opacity: 0.8;
}

.days-since-text {
  position: absolute;
  right: 0%;
  left: 0%;
  bottom: 30px;
}

.days-since-text h2 {
  font-family: 'Maytra Font', sans-serif;
  color: #434343;
  font-size: 2.5rem;
}

.day-configurator {
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.day-configurator label  {
  font-size: 1.2rem;
  padding-bottom: 0.8rem;
}

.day-configurator input  {
  font-size: 1.6rem;
}

.day-configurator label, .day-configurator input {
  display: block;
}


.form-submit-button {
  background-color: #fbeee0;
  border: 2px solid #422800;
  border-radius: 30px;
  box-shadow: #422800 4px 4px 0 0;
  color: #422800;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  font-size: 1rem;
  padding: 0 18px;
  line-height: 50px;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.form-submit-button:hover {
  background-color: #fff;
}

.form-submit-button:active {
  box-shadow: #422800 2px 2px 0 0;
  transform: translate(2px, 2px);
}

@media (min-width: 769px) {
  .form-submit-button {
    min-width: 120px;
    padding: 0 25px;
  }
}

.settings-modal {
  position: absolute;
  background: #F6F5F1;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  outline: none;
  border: none;
  width: 50%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  overflow: auto;
  padding: 2rem;
}

@media (max-width: 768px) {
  .polaroid-main-image {
    max-width: 200px;
  }

  .polaroid-tape-image {
    max-width: 100px;
  }

  .settings-modal {
    width: 80%;
  }

  .days-since-text {
    bottom: 10px;
  }
  
  .days-since-text h2 {
    font-size: 2rem;
  }

  .settings-icon-image {
    width: 16px;
    height: auto;
  }
}
